<template>
  <div class="dashboard-cards mt-3 ms-2 me-4">
    <b-row>
      <b-col cols="12" xl="6" v-for="model in models" :key="model.name">
        <b-card
          style="width: 100%; border: none; margin-bottom: 20px"
          header-tag="header"
          footer-tag="footer"
        >
          <div class="d-flex align-items-center gap-2">
            <div :class="['status-tag', model.status]">
              {{ model.status }}
            </div>
            <div class="model-name flex-grow-1 fs-6">
              {{ model.name }}
            </div>
            <div class="flex-grow-2">
              <router-link :to="`${$route.path}/${model.id}`">
                <PrimeButton class="model-button" icon="pi pi-arrow-right" />
              </router-link>
            </div>
          </div>
          <div class="d-flex">
            <div class="fw-light d-flex gap-2 flex-grow-1 text-capitalize">
              version<span class="model-name">{{ model.version }}</span>
            </div>
            <div
              class="fw-light d-flex gap-2 flex-grow-1 border-start border-2 ps-2"
            >
              Backend<span class="model-name">{{ model.backend }}</span>
            </div>
          </div>

          <PrimeButton
            label="Copy Model Link"
            class="copy-button mt-3"
            icon="pi pi-copy"
            @click="copyModelName(model.name)"
          />
          <div
            style="margin-top: 10px; background: #e7fbf5 !important"
            v-if="model.name"
          >
            <div class="footer-div py-3">
              <div class="row text-center footer-labels">
                <div class="col-3">
                  <img src="@/assets/images/server-icon.svg" />
                  <span class="ms-2" style="text-transform: capitalize"
                    >Count</span
                  >
                </div>
                <div class="col-3 left-thin-border">
                  <img src="@/assets/images/server-icon.svg" />
                  <span class="ms-2" style="text-transform: capitalize"
                    >Queue Duration</span
                  >
                </div>
                <div class="col-3 left-thin-border">
                  <img src="@/assets/images/server-icon.svg" />
                  <span class="ms-2" style="text-transform: capitalize"
                    >Instances</span
                  >
                </div>
                <div class="col-3 left-thin-border">
                  <img src="@/assets/images/server-icon.svg" />
                  <span class="ms-2" style="text-transform: capitalize"
                    >Failed Requests</span
                  >
                </div>
              </div>
              <div class="row text-center">
                <div class="col-3">{{ model.count }}</div>
                <div class="col-3 left-thin-border">
                  {{ model.queueDuration }}
                </div>
                <div class="col-3 left-thin-border">
                  {{ model.instances }}
                </div>
                <div class="col-3 left-thin-border">
                  {{ model.failedRequests }}
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "GridModel",
  props: {
    models: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getModelNameVersion(modelName) {
      return modelName.split("__");
    },
    getModelNameOnly(modelName) {
      return this.getModelNameVersion(modelName)[0];
    },
    copyModelName(modelName) {
      let formattedModelName = modelName.replace(/\s+/g, "_");
      let url =
        "http://triton.omniops.sa/v2/models/" +
        this.getModelNameOnly(formattedModelName);
      navigator.clipboard.writeText(url);

      this.$toast.add({
        severity: "success",
        summary: "Success Message",
        detail: "copied to clipboard",
        life: 3000,
      });
    },
  },
};
</script>

<style scoped>
.status-tag {
  font-size: 14px;
  line-height: 18px;
  padding: 5px 10px;
  border-radius: 16px;
  text-transform: capitalize;
}

.deploying {
  background-color: #f0f6ff;
  border: 1px solid #bfdbfe;
  color: #2463eb;
}

.deployed {
  background-color: #e7fbf5;
  border: 1px solid #5ae0bb;
  color: #0fa97e;
}

.conflict {
  background-color: #fff8f0;
  border: 1px solid #fee1bf;
  color: #c27113;
}

.model-name {
  color: #083e47;
  font-size: 16px;
  font-weight: 500;
}

.model-button {
  background-color: #13d39e !important;
  color: #021214 !important;
  border: none !important;
  width: 64px !important;
}

.copy-button {
  background-color: transparent !important;
  color: #13d39e !important;
  border: none !important;
}

.copy-button:hover {
  background-color: #e7fbf5 !important;
}
</style>
