<template>
  <div>
    <div
        :class="[
        'logo-container',
        'd-flex',
        'align-items-center',
        'justify-content-between'
      ]"
    >
      <img
          style="margin-left: 2px"
           src="../../assets/images/omniLogo.svg"
           alt="Omniops-Logo-small"
      />

    </div>
    <div class="links-list">
      <div v-for="(link, index) in links" :key="index">
        <div
            :class="[
            'link',
            'd-flex',
            'align-items-center',
            { active: link.is_active ? true : false },
          ]"
            @click.stop="selectLink(link.route_link)"
        >
          <div class="link-icon" v-if="link.icon">
            <i class="icon-color d-block"
               :style="'mask-image: url('+link.icon+');-webkit-mask-size: cover;mask-size: cover;-webkit-mask-image: url('+ link.icon+');'"
            />
          </div>
          <div class="link-label" style="white-space: nowrap;">{{ link.label }}</div>
        </div>
        <div
            v-if="
            link && link.sub_links && link.sub_links.length
          "
        >
          <div v-for="(sub_link, index) in link.sub_links" :key="index">
            <div
                :class="[
                'sub-link-label',
                { active: link.is_active && sub_link.is_active ? true : false },
              ]"
                @click.stop="selectLink(sub_link.route_link)"
            >
              {{ sub_link.label }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="notification-link">
      <div
          :class="[
          'link',
          'd-flex',
          'align-items-center',

        ]"
      >
        <div>
          <img src="../../assets/images/bell.png" alt="bell"/>
        </div>
        <div>
          <div
              :class="[
              'notification-count',
              'd-flex',
              'align-items-center',
              'justify-content-center',
            ]"
              :style="getNotificationCountStyle"
          >
            <div>2</div>
          </div>
        </div>
        <div class="link-label" style="overflow:hidden; margin-left: 10px">
          Notifications
        </div>
      </div>
    </div>

    <div
        :class="['user-info', { active: open_menu ? true : false }]"
        :style="`padding:5px 10px;${
        is_collapsed ? '' : 'width: 250px;'
      }`"
        @click.stop="toggle_menu"
    >
      <div class="d-flex align-items-center" style="position: relative">
        <div class="collapse-content" style="white-space: nowrap">
          <div class="user-name">{{ user.given_name }} {{ user.family_name }}</div>
          <div class="user-email">{{ user.email }}</div>
        </div>
        <div>
          <div
              class="user-avtar d-flex align-items-center justify-content-center"

          >
            {{ user.given_name.toString().charAt(0).toUpperCase() }}
            {{ user.family_name.toString().charAt(0).toUpperCase() }}
          </div>
        </div>

        <div
            id="menu-container"
            class="menu-list"
            style="left: 250px; top: -132px; width: 220px"
            v-if="open_menu"
            v-click-outside="toggle_menu"
        >
          <div
              class="d-flex align-items-center"
              style="border-bottom: 1px solid #d4d2cb; padding-bottom: 0.5rem"
          >
            <div>
              <div
                  class="user-avtar d-flex align-items-center justify-content-center"
                  :style="'margin-right: 0.5rem;'"
              >
                MA
              </div>
            </div>
            <div>
              <div class="user-name">{{ user.given_name }} {{ user.family_name }}</div>
              <div class="user-email">{{ user.email }}</div>
            </div>
          </div>
          <div
              v-for="(menu_item, index) in menu_items"
              :class="[
              'menu-item',
              'd-flex',
              'align-items-center',
              { active: selected_item === menu_item.label ? true : false },
            ]"
              :key="index"
              @click.stop="select_item(menu_item.label)"
          >
            <img
                :src="menu_item.icon"
                alt="icon"
                style="height: 32px; width: 32px; margin-right: 0.5rem"
            />
            <div>
              {{ menu_item.label }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from "@/services/auth.service";

export default {
  name: "side-bar",
  props: ["is_collapsed"],
  emits: ["alwaysOpenSidebar"],
  data() {
    const user = AuthService.GetUserInfo()
    let links = [

      {
        label: "Dashboard",
        icon: "/images/icons/home.svg",
        route_link: "/dashboard",
        is_active: true,
        sub_links: []
      },
      {
        label: "Harbor",
        icon: "/images/icons/harbor.svg",
        route_link: "/dashboard/harbor",
        is_active: false,
        sub_links: []
      },
      {
        label: "Gitlab",
        icon: "/images/icons/gitlab.svg",
        route_link: "/dashboard/gitlab",
        is_active: false,
        sub_links: []
      },
      {
        label: "Minio",
        icon: "/images/icons/minio.svg",
        route_link: "/dashboard/minio",
        is_active: false,
        sub_links: []
      },
      
      {
        label: "ArgoCD",
        icon: "/images/icons/argocd.svg",
        route_link: "/dashboard/argocd",
        is_active: false,
        sub_links: []
      },
     
      {
        label: "Airflow",
        icon: "/images/icons/airflow.svg",
        route_link: "/dashboard/airflow",
        is_active: false,
        sub_links: []
      },
      {
        label: "Triton Inference",
        icon: "/images/icons/triton.svg",
        route_link: "/dashboard/tritonInference",
        is_active: false,
        sub_links: []
      },
      {
        label: "Cloud Billing",
        icon: "/images/icons/cloud.svg",
        route_link: "/dashboard/billing/AWS",
        is_active: false,
        sub_links: [
          {
            label: "AWS",
            icon: "/images/users-icon.png",
            route_link: "/dashboard/billing/AWS",
            is_active: false,
          },
          {
            label: "GCP",
            icon: "/images/users-icon.png",
            route_link: "/dashboard/billing/GCP",
            is_active: false,
          },
          {
            label: "Azure",
            icon: "/images/users-icon.png",
            route_link: "/dashboard/billing/Azure",
            is_active: false,
          },
          {
            label: "OCI",
            icon: "/images/users-icon.png",
            route_link: "/dashboard/billing/OCI",
            is_active: false,
          },
        ],
      }
    ]
    if (user.app && user.app !== "xxx") {
      links = [
        {
          label: "Tickets",
          icon: "/images/tickets-icon.png",
          route_link: "/dashboard/tickets/queue",
          is_active: false,
          sub_links: [
            {
              label: "Queue",
              icon: "/images/tickets-icon.png",
              route_link: "/dashboard/tickets/queue",
              is_active: false,
            },
            {
              label: "Analytics",
              icon: "/images/analytics-icon.png",
              route_link: "/dashboard/tickets/analytics",
              is_active: false,
            },
            {
              label: "Users",
              icon: "/images/users-icon.png",
              route_link: "/dashboard/tickets/users",
              is_active: false,
            },
          ],
        }
      ]
    }
    return {
      user: user,
      links: links,
      open_menu: false,
      menu_items: [
        {
          label: "Account Settings",
          icon: "/images/account-setting.png",
        },
        {label: "Sign out", icon: "/images/sign-out.png"},
      ],
      selected_item: "",
    };
  },
  methods: {
    selectLink(val) {
      this.links = this.links.map((each) => {
        if (val && val !== this.$route.fullPath) this.$router.push(val);
        if (val && each && each.route_link && each.route_link === val) {
          each.is_active = true;
          each.sub_links = each.sub_links.map((sub_link) => {
            if (
                val &&
                sub_link &&
                sub_link.route_link &&
                sub_link.route_link === val
            )
              sub_link.is_active = true;
            else sub_link.is_active = false;
            return sub_link;
          });
        } else if (
            val &&
            each &&
            each.sub_links &&
            each.sub_links.length &&
            each.sub_links.find(
                (sub_link) => sub_link.route_link && sub_link.route_link === val
            )
        ) {
          each.is_active = true;
          each.sub_links = each.sub_links.map((sub_link) => {
            if (sub_link.route_link && sub_link.route_link === val)
              sub_link.is_active = true;
            else sub_link.is_active = false;
            return sub_link;
          });
        } else {
          each.is_active = false;
          each.sub_links = each.sub_links.map((sub_link) => {
            sub_link.is_active = false;
            return sub_link;
          });
        }
        return each;
      });
    },
    select_item(menu_item) {
      this.selected_item = menu_item;
      if (menu_item === "Account Settings") this.$router.push("/dashboard/settings/0");
      if (menu_item === "Sign out") this.$router.push("/logout");
      this.toggle_menu();
    },
    toggle_menu() {
      this.open_menu = !this.open_menu;
    },
  },
  computed: {
    getNotificationCountStyle() {
       return `height:18px;width:18px;margin-left:5px`;
    },
  },
  watch: {
    is_collapsed(val) {
      const collapseElementList =
          document.querySelectorAll(".collapse-content");
      if (!val) {
        [...collapseElementList].map((collapseEl) => {
          collapseEl.style.width = "100%";
        });
      } else {
        [...collapseElementList].map((collapseEl) => {
          collapseEl.style.width = null;
        });
      }
    },
    $route(val) {
      if (val && val.fullPath) {
        this.selectLink(val.fullPath);
      }
    },
    open_menu() {
      this.$emit("alwaysOpenSidebar");
    },
  },
  mounted() {
    const collapseElementList = document.querySelectorAll(".collapse-content");
    if (!this.is_collapsed) {
      [...collapseElementList].map((collapseEl) => {
        collapseEl.style.width = "100%";
      });
    } else {
      [...collapseElementList].map((collapseEl) => {
        collapseEl.style.width = null;
      });
    }

    if (this.$route && this.$route.fullPath) {
      this.selectLink(this.$route.fullPath);
    }
  },
};
</script>

<style scoped>

#sidebar-container .logo-container {
  height: 79px;
}

#sidebar-container .collapse-content {
  width: 0px;
  overflow: hidden;
  -webkit-transition: width 0.4s ease-in-out;
  -moz-transition: width 0.4s ease-in-out;
  -o-transition: width 0.4s ease-in-out;
  transition: width 0.4s ease-in-out;
}

#sidebar-container .link {
  cursor: pointer;
  padding: 10px;
  background-color: transparent;
  color: #89E9CF;
  height: 50px;
}

#sidebar-container .link:hover {
  background-color: #062D33;
  color: #89E9CF;
}

#sidebar-container .link.active {
  background-color: #13D39E;
  color: #062D33;
}

#sidebar-container .link-label {
  font-size: 16px;

  margin-left: 20px;
}

#sidebar-container .sub-link-label {
  cursor: pointer;
  background-color: transparent;
  color: #89E9CF;
  font-size: 15px;

  padding: 0.5rem 0rem;
  padding-left: 55px;
}

#sidebar-container .sub-link-label:hover {
  background-color: #062D33;
  color: #89E9CF;
}

#sidebar-container .sub-link-label.active {
  background-color: #89E9CF;
  color: #062D33;
}

#sidebar-container .notification-link {
  position: absolute;
  bottom: 70px;
  width: 100%;
}

#sidebar-container .notification-count {
  border-radius: 50%;
  background-color: #ce1a36;
  color: #fff;
  font-size: 11px;

}

#sidebar-container .user-info {
  position: fixed;
  bottom: 20px;
  cursor: pointer;
}

#sidebar-container .user-info:hover,
#sidebar-container .user-info.active {
  background-color: #89E9CF;
  color: #112849;
}

#sidebar-container .user-name {
  font-size: 13px;

  color: #89E9CF;
}

#sidebar-container .user-info:hover .user-name,
#sidebar-container .user-info.active .user-name {
  color: #89E9CF;
}

#sidebar-container .user-email {
  font-size: 13px;
  color: #ebe9e1;
}

#sidebar-container .user-info:hover .user-email,
#sidebar-container .user-info:hover .user-name{
  color: #112849;
}
#menu-container .user-email,
#menu-container .user-name,
#menu-container div
{
  color: #89E9CF!important;
}

#sidebar-container .user-avtar {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #7968d3;
  color: #ffffff;
  font-size: 10px;

}

.icon-color {
  height:30px;
  width: 30px;
  background-color: #89E9CF;
}
.link.active .icon-color {
  background-color: #062D33;
}

.link-icon {
  margin-left: 3px;
}
</style>
