<template>
  <div class="dashboard-cards mt-3 ms-2 me-4">
    <b-row>
      <b-col cols="12">
        <b-card
          v-for="model in models"
          :key="model.name"
          style="width: 100%; border: none; margin-bottom: 20px"
          header-tag="header"
          footer-tag="footer"
        >
          <div
            class="d-flex align-items-center justify-content-between flex-column flex-lg-row gap-2"
          >
            <div class="d-flex align-items-center gap-4">
              <div class="model-instances">
                {{ model.queueDuration }}MB /
                <span>{{ model.instances }}GB</span>
              </div>
              <div>
                <div class="model-name fs-6">
                  {{ model.name }}
                </div>
                <div class="d-flex gap-2">
                  <div class="fw-light d-flex text-capitalize gap-2">
                    version<span class="model-name">{{ model.version }}</span>
                  </div>
                  <div class="fw-light d-flex border-start border-2 gap-2 ps-2">
                    Backend<span class="model-name">{{ model.backend }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center gap-2">
              <div :class="['status-tag', model.status]">
                {{ model.status }}
              </div>
              <PrimeButton
                label="Copy Model Link"
                class="copy-button"
                icon="pi pi-copy"
                @click="copyModelName(model.name)"
              />
              <router-link :to="`${$route.path}/${model.id}`">
                <PrimeButton class="model-button" icon="pi pi-arrow-right" />
              </router-link>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "ListModel",
  props: {
    models: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getModelNameVersion(modelName) {
      return modelName.split("__");
    },
    getModelNameOnly(modelName) {
      return this.getModelNameVersion(modelName)[0];
    },
    copyModelName(modelName) {
      let formattedModelName = modelName.replace(/\s+/g, "_");
      let url =
        "http://triton.omniops.sa/v2/models/" +
        this.getModelNameOnly(formattedModelName);
      navigator.clipboard.writeText(url);

      this.$toast.add({
        severity: "success",
        summary: "Success Message",
        detail: "copied to clipboard",
        life: 3000,
      });
    },
  },
};
</script>

<style scoped>
.model-instances {
  padding: 10px 15px;
  background-color: #e7fbf5;
  color: #083e47;
  font-size: 20px;
}
.model-instances span {
  font-size: 12px;
  font-weight: 600;
  color: #083e47;
}

.status-tag {
  font-size: 14px;
  line-height: 18px;
  padding: 5px 10px;
  border-radius: 16px;
  text-transform: capitalize;
}

.deploying {
  background-color: #f0f6ff;
  border: 1px solid #bfdbfe;
  color: #2463eb;
}

.deployed {
  background-color: #e7fbf5;
  border: 1px solid #5ae0bb;
  color: #0fa97e;
}

.conflict {
  background-color: #fff8f0;
  border: 1px solid #fee1bf;
  color: #c27113;
}

.model-name {
  color: #083e47;
  font-size: 16px;
  font-weight: 500;
}

.model-button {
  background-color: #13d39e !important;
  color: #021214 !important;
  border: none !important;
  width: 64px !important;
  height: 38px !important;
}

.copy-button {
  background-color: transparent !important;
  color: #13d39e !important;
  border: none !important;
}

.copy-button:hover {
  background-color: #e7fbf5 !important;
}
</style>
