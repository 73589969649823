<template>
  <div
    class="position-fixed bg-white d-flex align-items-center justify-content-between"
    style="
      top: 0;
      height: 70px;
      padding: 0 4% 0 3%;
      z-index: 9;
      width: calc(100% - 55px);
    "
  >
    <div class="title fs-4 fw-bold" style="color: #062d33">
      <span
        style="
          opacity: 0.5;
          border-right: 2px gray solid;
          padding-right: 15px;
          margin-right: 10px;
        "
        v-if="parentLabel"
        >{{ parentLabel }}</span
      >
      {{ title }}
    </div>

    <div class="d-flex align-items-center gap-3">
      <ConfirmPopup></ConfirmPopup>
      <PrimeToast position="bottom-right" />
      <PrimeButton
        v-if="isModelDetails"
        label="Delete"
        class="delete-button p-button-danger"
        @click="deleteModel($event)"
      />
      <PrimeButton
        v-if="isAdvanced"
        label="Deactivate"
        class="deactivate-button p-button-outlined p-button-danger"
        @click="deactivateModel($event)"
      />
      <router-link
        class="btn m-1 px-4 py-2"
        id="header-button"
        v-if="buttonRouterTo"
        :to="buttonRouterTo"
      >
        <span class="fw-bold"> {{ buttonLabel }}</span>
      </router-link>
      <a class="btn m-1 px-4 py-2" id="header-button" v-if="href" :href="href">
        <span class="fw-bold"> {{ buttonLabel }}</span>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: "PageHeader",
  props: {
    title: String,
    buttonLabel: String,
    buttonRouterTo: String,
    parentLabel: String,
    href: String,
    isAdvanced: Boolean,
    modelId: String,
    isModelDetails: Boolean,
  },

  delete(event) {
    this.$confirm.require({
      target: event.currentTarget,
      message: "Are you sure you want to proceed?",
      icon: "pi pi-exclamation-triangle",
    });
  },
  methods: {
    showConfirmation(event, message, acceptCallback) {
      this.$confirm.require({
        target: event.currentTarget,
        message: message,
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          acceptCallback();
          this.$toast.add({
            severity: "success",
            summary: "Confirmed",
            detail: "You have accepted",
            life: 3000,
          });
        },
        reject: () => {
          this.$toast.add({
            severity: "error",
            summary: "Rejected",
            detail: "You have rejected",
            life: 3000,
          });
        },
      });
    },
    deactivateModel(event) {
      this.showConfirmation(
        event,
        "Are you sure you want to deactivate?",
        () => {
          // TODO
        }
      );
    },
    deleteModel(event) {
      this.showConfirmation(event, "Are you sure you want to delete?", () => {
        // TODO
      });
    },
  },
};
</script>
<style>
#insights-container .tab .value .red-label img {
  margin-right: 0.15rem;
  margin-top: -1px;
}

#header-button {
  background-color: #13d39e;
  color: #0b3133;
}

.deactivate-button {
  color: #dd3d05 !important;
  border-color: #dd3d05 !important;
  height: 40px !important;
}

.delete-button {
  background-color: #dd3d05 !important;
  border-color: #dd3d05 !important;
  height: 40px !important;
}

.p-confirm-popup {
  z-index: 10;
}
</style>
