var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"content-container"}},[(_vm.loading)?_c('common-loading'):(_vm.error && _vm.error.length)?_c('div',[_c('b-alert',{attrs:{"show":_vm.error && _vm.error.length ? true : false,"variant":"danger"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.error || '&nbsp;')}})])],1):(_vm.data && _vm.data.length)?_c('div',[_c('div',{staticStyle:{"margin-top":"1rem"}},[_c('div',{staticClass:"Model-title"},[_vm._v("Model Details")]),_c('b-row',[_c('b-col',{attrs:{"md":"4","xl":"2"}},[_c('div',{staticClass:"bill-queue"},_vm._l((_vm.data),function(each,index){return _c('div',{key:index,staticStyle:{"position":"relative"}},[_c('div',{staticClass:"bill-queue-tab d-flex align-items-center",class:[
                  {
                    disabled: each.disabled,
                    active: _vm.selected_tab_index === index,
                  },
                ],on:{"click":function($event){$event.stopPropagation();return _vm.selectTab(index)}}},[_c('div',{staticClass:"bill-queue-label"},[_vm._v(" "+_vm._s(each.service)+" ")])])])}),0)]),_c('b-col',{attrs:{"md":"8","xl":"10"}},[_c('div',{staticClass:"bill-detail"},[_c('div',{staticClass:"service-container"},_vm._l((_vm.data.find((e, i) => {
                  if (i === _vm.selected_tab_index) return e;
                }).usage_type_costs),function(service,index){return _c('div',{key:index},[_c('div',{staticClass:"service d-flex py-2"},[_c('div',{staticClass:"usage-type"},[_vm._v(" "+_vm._s(service.usage_type)+" ")]),(service.usage.length)?_c('div',{staticClass:"d-flex"},_vm._l((service.usage),function(value,index){return _c('div',{key:index},[_c('div',{staticClass:"input-cells mx-2"},[_vm._v(" "+_vm._s(value)+" ")])])}),0):_vm._e()])])}),0)])])],1)],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }