<template>
  <div id="omni-footer">
    Copyrights 2024 Omniops | Legal | Privacy Policy
  </div>
</template>
<script>
export default {
  name: 'OmniOpsFooter',
  props: {}
}
</script>
<style>
#omni-footer {
  position: relative;
  bottom: 0;
  right:0;
  background: lightgray;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  padding-top: 12px;
}
</style>