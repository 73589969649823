<template>
  <div id="main-container">
    <div
        id="sidebar-container"
        :style="is_collapsed && !is_always_open ? 'width:55px' : 'width:250px'"
        @mouseenter="mouseenter"
        @mouseleave="mouseleave"
    >
      <the-sidebar
          :is_collapsed="is_always_open ? false : is_collapsed"
          @alwaysOpenSidebar="alwaysOpenSidebar"
      ></the-sidebar>
    </div>
    <div id="content-container">
      <div style="min-height: calc(100vh - 160px);">
        <router-view v-slot="slotProps">
          <transition name="route" mode="out-in">
            <component :is="slotProps.Component"></component>
          </transition>
        </router-view>
      </div>
      <OmniOpsFooter/>
    </div>


  </div>
</template>

<script>
import TheSidebar from "./TheSidebar.vue";
import OmniOpsFooter from "@/components/layout/OmniOpsFooter.vue";

export default {
  name: "main-layout",
  components: {OmniOpsFooter, TheSidebar},
  data() {
    return {
      is_collapsed: true,
      is_always_open: false,
    };
  },
  methods: {
    mouseenter() {
      this.is_collapsed = false;
    },
    mouseleave() {
      this.is_collapsed = true;
    },
    alwaysOpenSidebar() {
      this.is_always_open = !this.is_always_open;
    },
  },
};
</script>

<style scoped>
#main-container {
  display: flex;
}

#sidebar-container {
  position: fixed;
  height: 100vh;
  z-index: 999999;
  /* padding: 0px 10px 25px 10px; */
  overflow: hidden;
  background-color: #021214;
  -webkit-transition: width 0.4s ease-in-out;
  -moz-transition: width 0.4s ease-in-out;
  -o-transition: width 0.4s ease-in-out;
  transition: width 0.4s ease-in-out;
}

#content-container {
  height: 100vh;
  min-height: 100vh;
  width: 100%;
  margin-left: 55px;
  overflow: auto;
  background-color: #F9F9F9;
}
</style>
