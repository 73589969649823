<template>
  <div class="w-100">
    <PageHeader
      title="Triton Inference Dashboard"
      buttonLabel="New Model"
      buttonRouterTo="triton-inference/create-model"
    />

    <div class="margin-under-header">
      <common-loading v-if="loading" />

      <div id="insights-container">
        <div class="tabs grid-auto-fir-minmax">
          <div
            style="padding: 0rem 0.25rem"
            v-for="insight in insights"
            :key="insight.id"
          >
            <div class="tab">
              <div class="left-border">
                <div
                  class="label"
                  style="
                    text-transform: capitalize;
                    font-weight: bold;
                    padding-left: 10px;
                  "
                >
                  {{ insight.name }}
                </div>
                <div class="value" style="padding-left: 10px" v-if="insights">
                  <span style="">{{ Math.round(insight.value) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!loading" class="d-flex align-items-end">
        <div class="fs-4 fw-bold mx-5 pe-5 mt-4 flex-grow-1">Models</div>
        <div class="d-flex flex-grow-2 gap-2">
          <SelectButton
            v-model="display"
            :options="justifyOptions"
            optionValue="value"
            dataKey="value"
            class="d-flex flex-nowrap"
          >
            <template #option="slotProps">
              <i :class="slotProps.option.icon"></i>
            </template>
          </SelectButton>
          <div class="me-5">
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                type="text"
                v-model="searchInput"
                placeholder="Search models..."
              />
            </span>
          </div>
        </div>
      </div>
      <!--  Cards -->
      <div class="center-div" id="no-projects" v-if="!models">
        <h1>
          No projects
          <b-icon-archive></b-icon-archive>
        </h1>
      </div>
      <b-card-group deck v-else>
        <GridModel v-if="display === 'grid'" :models="models" />
        <ListModel v-else :models="models" />
      </b-card-group>
    </div>

    <PrimePaginator
      :first.sync="offset"
      :rows.sync="rows"
      :totalRecords="totalRecords"
      @page="onPage($event)"
    ></PrimePaginator>
  </div>
</template>

<script>
//import TritonInferenceService from "@/services/tritonInference.service";
import PageHeader from "@/components/ui/PageHeader.vue";
import GridModel from "@/components/layout/GridModel.vue";
import ListModel from "@/components/layout/ListModel.vue";

export default {
  name: "Triton-inference-dashboard",

  components: { PageHeader, GridModel, ListModel },
  data() {
    return {
      offset: 0,
      rows: 10,
      totalRecords: 100,
      searchInput: null,
      error: null,
      loading: false,
      dashboardData: {},
      insights: [
        {
          name: "GPU Memory Used",
          value: 10,
        },
        {
          name: "GPU Power usage",
          value: 70,
        },
        {
          name: "GPU Power Limit",
          value: 100,
        },
      ],
      showDialog: false,
      display: "grid",
      justifyOptions: [
        { icon: "pi pi-th-large", value: "grid" },
        { icon: "pi pi-align-justify", value: "list" },
      ],
      models: [
        {
          id: 1,
          status: "deploying",
          name: "model_name",
          version: "model_version",
          backend: "ONNX",
          count: 10,
          queueDuration: 100,
          instances: 1,
          failedRequests: 0,
        },
        {
          id: 2,
          status: "deploying",
          name: "model name 2",
          version: "model_version",
          backend: "ONNX",
          count: 10,
          queueDuration: 100,
          instances: 1,
          failedRequests: 0,
        },
        {
          id: 3,
          status: "conflict",
          name: "model_name 3",
          version: "model_version",
          backend: "ONNX",
          count: 10,
          queueDuration: 100,
          instances: 1,
          failedRequests: 0,
        },
        {
          id: 4,
          status: "deploying",
          name: "model_name 4",
          version: "model_version",
          backend: "ONNX",
          count: 10,
          queueDuration: 100,
          instances: 1,
          failedRequests: 0,
        },
        {
          id: 5,
          status: "deployed",
          name: "model_name 5",
          version: "model_version",
          backend: "ONNX",
          count: 10,
          queueDuration: 100,
          instances: 1,
          failedRequests: 0,
        },
      ],
      dashboard: {},
      allowedInsights: [
        // "nv_inference_request_success",
        "nv_inference_count",
        // "nv_inference_exec_count",
        // "nv_inference_request_duration_us",
        "nv_inference_queue_duration_us",
        "nv_inference_compute_input_duration_us",
        // "nv_inference_compute_infer_duration_us",
        // "nv_inference_compute_output_duration_us",
      ],
      insightLabels: {
        nv_inference_request_success: "request success",
        nv_inference_count: "count",
        nv_inference_exec_count: "exec count",
        nv_inference_request_duration_us: "request duration us",
        nv_inference_queue_duration_us: "queue duration us",
        nv_inference_compute_input_duration_us: "compute input duration us",
        nv_inference_compute_infer_duration_us: "compute infer duration us",
        nv_inference_compute_output_duration_us: "compute output duration us",

        nv_energy_consumption: "energy consumption",
        nv_gpu_utilization: "gpu utilization",
        nv_gpu_memory_total_bytes: "gpu memory total bytes",
        nv_gpu_memory_used_bytes: "gpu memory used",
        nv_gpu_power_usage: "gpu power usage",
        nv_gpu_power_limit: "gpu power limit",
      },
    };
  },
  mounted() {
    //TODO: Fetch data from API
    //this.loading = true;
    //this.error = null;
    // TritonInferenceService.GetStats().then(({data}) => {
    // this.models = data.models
    // this.insights = data.insights
    //})
    //.catch(({message}) => {
    //this.error = message
    //  ? message
    //: "There was an error in loading lookups.";
    // })
    //.finally(() => {
    // this.loading = false;
    //});
  },
  computed: {
    apiParams() {
      return {
        offset: this.offset,
        limit: this.rows,
      };
    },
  },
  methods: {
    onPage(event) {
      this.offset = event.first;
      this.rows = event.rows;
    },
    getFilteredModels() {
      if (!this.searchInput) {
        return this.models;
      } else {
        let result = {};
        for (let modelName of Object.keys(this.models)) {
          if (modelName.includes(this.searchInput))
            result[modelName] = this.models[modelName];
        }
        return result;
      }
    },
    getQueueDuration(model) {
      let queueDuration = model.nv_inference_queue_duration_us;
      return Math.round((1000 * queueDuration) / 2789410, 2);
      // ms : 1000*queue duration us/2789410
    },
    getComputeInputDuration(model) {
      let computeInput = model.nv_inference_compute_input_duration_us;
      let count = model.nv_inference_count;
      return Math.round((1000 * computeInput) / count, 2);
      // 1000*compute input duration us/count
    },
    getGPUMemoryUsed() {
      let gpuMemoryUsed = this.insights.nv_gpu_memory_used_bytes;
      return Math.round(gpuMemoryUsed / 1e9, 2);
      // gpu memory used GB: gpu memory used bytes/1e+9
    },
    getModelNameVersion(modelName) {
      return modelName.split("__");
    },
    getModelNameOnly(modelName) {
      return this.getModelNameVersion(modelName)[0];
    },
    getModelVersionOnly(modelName) {
      return this.getModelNameVersion(modelName)[1];
    },
    copyModelName(modelName) {
      let formattedModelName = modelName.replace(/\s+/g, "_");
      let url =
        "http://triton.omniops.sa/v2/models/" +
        this.getModelNameOnly(formattedModelName);
      navigator.clipboard.writeText(url);

      this.$toast.add({
        severity: "success",
        summary: "Success Message",
        detail: "copied to clipboard",
        life: 3000,
      });
    },
  },
};
</script>

<style>
.status-tag {
  font-size: 14px;
  line-height: 18px;
  padding: 5px 10px;
  border-radius: 16px;
  text-transform: capitalize;
}

.deploying {
  background-color: #f0f6ff;
  border: 1px solid #bfdbfe;
  color: #2463eb;
}

.deployed {
  background-color: #e7fbf5;
  border: 1px solid #5ae0bb;
  color: #0fa97e;
}

.conflict {
  background-color: #fff8f0;
  border: 1px solid #fee1bf;
  color: #c27113;
}

.model-name {
  color: #083e47;
  font-size: 16px;
  font-weight: 500;
}

.model-button {
  background-color: #13d39e !important;
  color: #021214 !important;
  border: none !important;
  width: 64px !important;
  height: 38px !important;
}

.copy-button {
  background-color: transparent !important;
  color: #13d39e !important;
  border: none !important;
}

.copy-button:hover {
  background-color: #e7fbf5 !important;
}

.p-inputtext {
  padding: 0.5rem 0.75rem !important;
}

.p-input-icon-left > .p-inputtext {
  padding-left: 2.5rem !important;
}

#insights-container {
  padding: 0px 30px;
}

#insights-container .tab {
  background-color: #e7fbf5;
  border-radius: 4px;
  border: 1px solid #89e9cf;
  color: #112849;
  text-align: left;
  padding: 15px;
  cursor: pointer;
}

#insights-container .tab .label {
  font-size: 14px;
}

#insights-container .tab .value {
  font-size: 26px;
}

#insights-container .tab .value .red-label {
  font-size: 14px;
  color: #ce1a36;
  margin-left: 0.25rem;
}

#insights-container .tab .value .red-label img {
  margin-right: 0.15rem;
  margin-top: -1px;
}

.left-border {
  border-left: #89e9cf 3px solid;
}

.left-thin-border {
  border-left: #89e9cf 1px solid;
}

.search-input-container {
  border: solid 2px #13d39e;
  border-radius: 8px;
  padding: 2px 10px;
  width: 200px;
}

.clearButton {
  display: inline;
  font-weight: 700;
  font-family: cursive;
  float: right;
  cursor: pointer;
}

.search-input-container > input,
.search-input-container > input:focus-visible {
  background-color: transparent;
  border: 0px solid;
  outline: none;
  width: 150px;
}

.grid-auto-fir-minmax {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 1rem;
}

.p-paginator {
  background-color: #f9f9f9 !important;
}
</style>
