import axios from "axios";

import Vue from "vue";
import VueAxios from "vue-axios";

import router from "../../router";

import qs from "qs";

import {JWTService} from "../../storage";
import {APIError, APIResponse} from "./utils";

const CONTENT_TYPE = "application/x-www-form-urlencoded";
const x_api_key = "AZ87-6563-XUJH-00002";

const isObject = (data, messages) => {
    const keys = Object.keys(data);
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (Array.isArray(data[key])) {
            for (let j = 0; j < data[key].length; j++) {
                const element = data[key][j];
                if (Array.isArray(element)) {
                    isArray(element, messages);
                }
                if (typeof element === "object") {
                    isObject(element, messages);
                }
                if (typeof element === "string") {
                    const key_parts = key.split("_");
                    let modified_key_parts = [];

                    for (let i = 0; i < key_parts.length; i++) {
                        let key_part = key_parts[i];
                        key_part =
                            key_part.charAt(0).toLocaleUpperCase() +
                            key_part.slice(1, key_part.length);
                        modified_key_parts.push(key_part);
                    }

                    let modifiedMessage = element
                        .toLowerCase()
                        .replace("this field", modified_key_parts.join(" "));

                    modifiedMessage = modifiedMessage
                        .toLowerCase()
                        .replace("this value", modified_key_parts.join(" "));

                    if (modifiedMessage?.length) {
                        modifiedMessage =
                            modifiedMessage.charAt(0).toLocaleUpperCase() +
                            modifiedMessage.slice(1, modifiedMessage.length);
                    }
                    if (messages.findIndex((each) => each === modifiedMessage) < 0)
                        messages.push(modifiedMessage);
                }
            }
        }
        if (typeof data[key] === "object") {
            isObject(data[key], messages);
        }
    }
};

const isArray = (data, messages) => {
    for (let i = 0; i < data.length; i++) {
        const element = data[i];
        if (Array.isArray(element)) {
            isArray(element, messages);
        }
        if (typeof element === "object") {
            isObject(element, messages);
        }
        if (typeof element === "string") {
            if (messages.findIndex((each) => each === element) < 0) ;
            messages.push(element);
        }
    }
};

class APIService {
    constructor() {
        let client = axios.create({
            timeout: parseInt(process.env.VUE_APP_TIMEOUT),
            headers: {
                "Content-Type": CONTENT_TYPE,
            },
        });

        client.interceptors.request.use(
            (config) => {
                this.setAuthHeader(config);

                return config;
            },
            (error) => {
                return Promise.reject(new APIError(error));
            }
        );

        client.interceptors.response.use(
            (response) => {
                return Promise.resolve(new APIResponse(response));
            },
            (error) => {
                if (!error.response) return Promise.reject(new APIError(error));

                if (error.response.status === 400) {
                    let temp_error = {...error};
                    if (temp_error?.response?.data?.details) {
                        const {details} = temp_error?.response?.data;
                        let messages = [];
                        if (typeof details === "object") {
                            isObject(details, messages);
                        }
                        if (Array.isArray(details)) {
                            isArray(details, messages);
                        }

                        temp_error.response.data.message =
                            "<div>" + messages.join("</div><div>") + "</div>";
                    }
                    return Promise.reject(new APIError(temp_error));
                }

                if (error.response.status === 401) {
                    //   router.push({
                    //     name: "refresh-token",
                    //     query: { next: window.location.pathname },
                    //   });

                    const refresh_token_url = process.env.VUE_APP_KEYCLOAK_URL + `/token`,
                        payload = {
                            client_id: `omniops`,
                            client_secret: `Ta0YwJBmntT8h204CpDgWVaClmgewChE`,
                            refresh_token: JWTService.getRefreshToken(),
                            grant_type: `refresh_token`,
                        };

                    return client
                        .post(refresh_token_url, payload)
                        .then(({data}) => {
                            const config = error.config;

                            JWTService.setToken(data.access_token);
                            JWTService.setRefreshToken(data.refresh_token);
                            JWTService.setIdToken(data.id_token);

                            this.setAuthHeader(config);

                            return new Promise((resolve, reject) => {
                                client
                                    .request(config)
                                    .then((response) => {
                                        resolve(new APIResponse(response));
                                    })
                                    .catch((error) => {
                                        reject(new APIError(error));
                                    });
                            });
                        })
                        .catch((error) => {
                            router.push({name: "logout"});

                            return Promise.reject(new APIError(error));
                        });
                }

                return Promise.reject(new APIError(error));
            }
        );

        Vue.use(VueAxios, client);
    }

    /**
     *
     * @param {AxiosRequestConfig} config
     */
    setAuthHeader(config) {
        // config.data = JSON.stringify(config.data);

        let token = JWTService.getToken();

        if (token && (config.url.includes(process.env.VUE_APP_API_URL) || config.url.includes(process.env.VUE_APP_HARBOR_API_URL))) {
            config.headers["Authorization"] = `Bearer ${token}`;
            config.headers["x-api-key"] = x_api_key;
            config.headers["Content-Type"] = 'application/json';
        } else {
            config.data = qs.stringify(config.data);
        }
    }
}

export default APIService;
