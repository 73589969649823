<template>
  <div
    id="refresh-token-container"
    class="d-flex justify-content-center align-items-center"
  >
    <common-loading v-if="loading" />
    <div v-else-if="error">
      <div class="error" v-html="error"></div>
    </div>
  </div>
</template>

<script>
import { TOKEN_REFRESH } from "../../store/auth.module.js";
import { JWTService } from "../../storage";

export default {
  name: "refresh-token-page",
  data() {
    return { error: "", loading: false };
  },
  mounted() {
    this.loading = true;

    const payload = {
      client_id: `omniops`,
      client_secret: `Ta0YwJBmntT8h204CpDgWVaClmgewChE`,
      refresh_token: JWTService.getRefreshToken(),
      grant_type: `refresh_token`,
    };

    return this.$store
      .dispatch(TOKEN_REFRESH, payload)
      .then(() => {
        if (this.$route.query.next)
          return this.$router.push(this.$route.query.next);
        else return this.$router.push({ name: "Tickets" });
      })
      .catch(({ message }) => {
        this.error = message
          ? message
          : "There was an error in refreshing token.";
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>

<style scoped>
#refresh-token-container {
  height: 100vh;
}
#refresh-token-container .error {
  font-size: 22px;

  color: #f44336;
}
</style>
