<template>
  <div id="content-container">
    <common-loading v-if="loading" />
    <div v-else-if="error && error.length">
      <b-alert :show="error && error.length ? true : false" variant="danger">
        <div v-html="error || '&nbsp;'"></div>
      </b-alert>
    </div>
    <div v-else-if="data && data.length">
      <div style="margin-top: 1rem">
        <div class="Model-title">Model Details</div>
        <b-row>
          <b-col md="4" xl="2">
            <div class="bill-queue">
              <div
                v-for="(each, index) in data"
                :key="index"
                style="position: relative"
              >
                <div
                  class="bill-queue-tab d-flex align-items-center"
                  :class="[
                    {
                      disabled: each.disabled,
                      active: selected_tab_index === index,
                    },
                  ]"
                  @click.stop="selectTab(index)"
                >
                  <div class="bill-queue-label">
                    {{ each.service }}
                  </div>
                </div>
              </div>
            </div>
          </b-col>
          <b-col md="8" xl="10">
            <div class="bill-detail">
              <div class="service-container">
                <div
                  v-for="(service, index) in data.find((e, i) => {
                    if (i === selected_tab_index) return e;
                  }).usage_type_costs"
                  :key="index"
                >
                  <div class="service d-flex py-2">
                    <div class="usage-type">
                      {{ service.usage_type }}
                    </div>
                    <div class="d-flex" v-if="service.usage.length">
                      <div v-for="(value, index) in service.usage" :key="index">
                        <div class="input-cells mx-2">
                          {{ value }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
// import CostService from "../../../../services/cost.service";

export default {
  name: "content-page",
  components: {},
  data() {
    return {
      loading: false,
      error: null,
      data: [
        {
          service: "general details",
          disabled: false,
          usage_type_costs: [
            {
              usage_type: "On-Demand",
              usage: [100, 200, 300, 400],
            },
            {
              usage_type: "On-Demand 2",
              usage: [100, 200, 300],
            },
          ],
        },
        {
          service: "inputs",
          disabled: false,
          usage_type_costs: [
            {
              usage_type: "aws ec2",
              usage: [100, 200, 300, 400],
            },
            {
              usage_type: "aws free tier",
              usage: [100, 200, 300],
            },
            {
              usage_type: "aws free tier",
              usage: [100],
            },
          ],
        },
        {
          service: "outputs",
          disabled: false,
          usage_type_costs: [
            {
              usage_type: "data transfer",
              usage: [100, 200, 300, 400],
            },
          ],
        },
      ],
      bill_detail_tab: ["Service", "Regions"],
      selected_tab_index: 0,
      open_menu_index: null,
      open_year_menu: false,
      open_month_menu: false,
    };
  },
  methods: {
    toggle_filter_menu(index) {
      if (this.open_menu_index === index) this.open_menu_index = null;
      else this.open_menu_index = index;
    },
    selectTab(index) {
      this.selected_tab_index = index;
    },
    openYearMenu() {
      this.open_year_menu = !this.open_year_menu;
      this.open_month_menu = false;
    },
    openMonthMenu() {
      this.open_year_menu = false;
      this.open_month_menu = !this.open_month_menu;
    },
    // changeFilter(key, val) {
    //   console.log(key, val);
    //   this.query();
    // },
    // query(params = {}) {
    //   this.loading = true;
    //   this.error = null;

    //   CostService.Details(params)
    //     .then(({ data }) => {
    //       this.data = data;
    //     })
    //     .catch(({ message }) => {
    //       this.error = message
    //         ? message
    //         : "There was an error in loading stats.";
    //     })
    //     .finally(() => {
    //       this.loading = false;
    //     });
    // },
  },
  mounted() {
    // this.query();
  },
};
</script>

<style scoped>
#content-container {
  margin: 1.5rem;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #ebe9e1;
  padding: 0 20px 20px 20px;
}

#content-container .top-container {
  padding-bottom: 1rem;
  border-bottom: 1px solid #ebe9e1;
}

#content-container .top-container .filter-tab {
  background-color: #89e9cf;
  color: #112849;
  border-radius: 6px;

  font-size: 14px;
  padding: 5px 10px;
  text-align: center;
  cursor: pointer;
}

#content-container .bill-queue-tab {
  background-color: #f9f9f9;
  color: #112849;

  font-size: 14px;
  font-weight: 700;
  min-height: 50px;
  padding: 10px 20px;
  margin-bottom: 0.5rem;
  cursor: pointer;
  text-transform: capitalize;
}

#content-container .bill-queue-tab.active {
  background-color: #0fa97e;
  color: #f9f9f9;
  padding: 10px 20px 10px 20px;
}

#content-container .bill-queue-tab.active::after {
  content: "";
  position: absolute;
  right: -39px;
  border: solid 25px transparent;
  border-left-color: #0fa97e;
  border-left-width: 15px;
}

#content-container .bill-queue-tab.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
  color: #d4d2cb;
}

#content-container .bill-queue-value {
  margin-right: 0.5rem;
}

#content-container .bill-detail {
  min-height: 450px;
  border-radius: 4px;
  border: 1px solid #ebe9e1;
  padding: 15px;
}

#content-container .bill-detail .bill-detail-tab {
  cursor: pointer;
  background-color: #f9f9f9;
  border-radius: 6px;
  color: #7e8282;
  font-size: 14px;

  padding: 6px 12px;
  margin: 5px 0px;
}

#content-container .bill-detail .bill-detail-tab:hover {
  background-color: #ebe9e1;
}

#content-container .bill-detail .bill-detail-tab.active {
  background-color: #112849;
  color: #89e9cf;
}

#content-container .bill-detail .filter-container {
  border-bottom: 1px solid #ebe9e1;
  padding-bottom: 1rem;
}

#content-container .bill-detail .service-container {
  max-height: 450px;
  overflow: auto;
}

#content-container .bill-detail .service-container::-webkit-scrollbar {
  height: 0px;
  width: 0px;
}

#content-container .bill-detail .service {
  color: #112849;
  font-size: 14px;

  margin: 5px 0px;
}

#content-container .bill-detail .sub_service {
  color: #5a6062;
  font-size: 14px;

  margin: 5px 0px;
}

#content-container .bill-detail .sub_sub_service {
  color: #5a6062;
  font-size: 14px;

  margin: 5px 0px;
}

.Model-title {
  font-size: 18px;
  font-weight: 700;
  color: #083e47;
  padding-bottom: 15px;
}

.usage-type {
  font-size: 14px;
  font-weight: 500;
  color: #021214;
  width: 150px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  text-transform: capitalize;
}

.input-cells {
  background-color: #f3f4f4;
  color: #112849;
  font-size: 14px;
  font-weight: 500;
  min-width: 50px;
  min-height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
