<template>
  <div>
    <PageHeader title="Dashboard" />
  <div id="registration-container" class="margin-under-header">
    <common-loading v-if="loading"/>

    <b-row class="tabs" style="padding: 0rem 0.5rem">
      <b-col style="padding: 0rem 0.25rem">
        <div class="tab">
          <div class="label">Average Monthly Charges</div>
          <div class="value">
            <span>1242$</span>
          </div>
        </div>
      </b-col>
      <b-col style="padding: 0rem 0.25rem">
        <div class="tab">
          <div class="label">Charges This Month</div>
          <div class="value d-flex align-items-center justify-content-center">
            <span>890$</span>
            <div
                class="red-label d-flex align-items-center justify-content-center"
            >
              <img
                  src="../../assets/images/red_up_arrow_circle.png"
                  alt="red_up_arrow_circle"
              />
              5%
            </div>
          </div>
        </div>
      </b-col>
      <b-col style="padding: 0rem 0.25rem">
        <div class="tab">
          <div class="label">Charges Last Month</div>
          <div class="value d-flex align-items-center justify-content-center">
            <span>3000$</span>
            <div
                class="red-label d-flex align-items-center justify-content-center"
            >
              <img
                  src="../../assets/images/red_up_arrow_circle.png"
                  alt="red_up_arrow_circle"
              />
              10%
            </div>
          </div>
        </div>
      </b-col>
    </b-row>

    <div class="container-fluid pt-5">
      <div class="row">
        <div class="col-12">
          <h4>Monthly Costs</h4>
          <apex-chart height="400" type="line" :options="options" :series="series1"></apex-chart>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <h4>Commits per Month</h4>
          <div>
            <apex-chart height="400" type="bar" :options="options" :series="series2"></apex-chart>
          </div>
        </div>
        <div class="col-6">
          <h4>Tools Usage</h4>
          <div>
            <apex-chart height="400" type="pie" :options="options2" :series="series3"></apex-chart>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader.vue";

export default {
  name: 'Main-Dashboard',
  components: {PageHeader},
  data: function () {
    return {
      loading: false,
      options: {
        chart: {
          id: 'vuechart-example',
          type: 'bar' // Ensure the chart is a bar chart
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          labels: {
            show: true // Ensure labels are shown on the x-axis
          }
        },
        // Other options...
      },
      options2: {
        chart: {
          id: 'vuechart-example3',
          type: 'pie',
        },
        labels: ['Harbor', 'Gitlab', 'Minio', 'MlFlow'],
        colors: ['#FF4560', '#00E396', '#FEB019', '#775DD0'], // Customize colors as needed
        legend: {
          position: 'bottom' // Customize legend position as needed
        },
        series: [44, 55, 13, 43]
      },
      series1: [{
        name: 'Cost',
        data: [1200, 1500, 1400, 2000, 2100, 2200, 1800, 1600, 1400, 1300, 1200, 1700] // Sample costs
      }],
      series2: [{
        name: 'Commits',
        data: [45, 60, 75, 50, 95, 80, 65, 75, 85, 100, 90, 120] // Sample data representing commits per month
      }],
      series3: [44, 55, 13, 43]
    }
  }
};
</script>

<style scoped>

#registration-container {
  padding: 0px 25px;
}

#registration-container .tab {
  background-color: #89E9CF;
  border-radius: 4px;
  color: #112849;
  text-align: center;
  padding: 15px;
  cursor: pointer;
}

#registration-container .tab .label {

  font-size: 14px;
}

#registration-container .tab .value {

  font-size: 26px;
}

#registration-container .tab .value .red-label {

  font-size: 14px;
  color: #ce1a36;
  margin-left: 0.25rem;
}

#registration-container .tab .value .red-label img {
  margin-right: 0.15rem;
  margin-top: -1px;
}
</style>