import Vue from "vue";
import Router from "vue-router";

import store from "./store";

// import Login from "./pages/Auth/Login.vue";
import Logout from "./pages/Auth/Logout.vue";
// import ForgotPassword from "./pages/Auth/ForgotPassword.vue";
// import ResetPassword from "./pages/Auth/ResetPassword.vue";
import Callback from "./pages/Auth/Callback.vue";
import RefreshToken from "./pages/Auth/RefreshToken.vue";
import NotFound from "./pages/NotFound.vue";
import NotAuthenticated from "./pages/NotAuthenticated.vue";

import TheMainLayout from "./components/layout/TheMainLayout.vue";
import Tickets from "./pages/Tickets/index.vue";
import Analytics from "./pages/Analytics/index.vue";
import Users from "./pages/Users/index.vue";
import Billing from "./pages/Billing/index.vue";
import Harbor from "./pages/Harbor/Registration/index.vue";
import Gitlab from "./pages/Gitlab/Registration/index.vue";
import TritonInference from "./pages/TritonInference/Dashboard/index.vue";
import TritonInferenceDetails from "./pages/TritonInference/ModelDetails/index.vue";
import CreateNewModel from "./pages/TritonInference/NewModel/index.vue";
import Minio from "./pages/Minio/Registration/index.vue";
import ArgoCD from "./pages/ArgoCD/Registration/index.vue";
import Settings from "./pages/Settings/index.vue";
import Dashboard from "./pages/Harbor/Dashboard/index.vue";
import GitlabDashboard from "./pages/Gitlab/Dashboard/index.vue";
import MinioDashboard from "./pages/Minio/Dashboard/index.vue";
import ArgoCDDashboard from "./pages/ArgoCD/Dashboard/index.vue";
import AirflowDashboard from "./pages/airflow/Dashboard/index.vue";
import Landing from "./pages/Landing/index.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      name: "logout",
      meta: { title: "Logout", requiresAuth: true },
      path: "/logout",
      component: Logout,
    },
    {
      name: "pre_login",
      meta: { title: "NotAuthenticated", requiresAuth: false },
      path: "/",
      component: NotAuthenticated,
    },
    {
      name: "callback",
      meta: { title: "Callback", requiresAuth: false },
      path: "/callback",
      component: Callback,
    },
    {
      name: "refresh-token",
      meta: { title: "RefreshToken", requiresAuth: true },
      path: "/refresh-token",
      component: RefreshToken,
    },
    {
      path: "/dashboard",
      component: TheMainLayout,
      children: [
        {
          name: "Tickets",
          meta: { title: "Tickets", requiresAuth: true },
          path: "tickets/queue",
          component: Tickets,
        },
        {
          name: "Dashboard",
          meta: { title: "Dashboard", requiresAuth: true },
          path: "/",
          component: Landing,
        },
        {
          name: "Analytics",
          meta: { title: "Analytics", requiresAuth: true },
          path: "tickets/analytics",
          component: Analytics,
        },
        {
          name: "Users",
          meta: { title: "Users", requiresAuth: true },
          path: "tickets/users",
          component: Users,
        },
        {
          name: "Billing",
          meta: { title: "Billing", requiresAuth: true },
          path: "billing/:billing_type",
          component: Billing,
        },
        {
          name: "Settings",
          meta: { title: "Settings", requiresAuth: true },
          path: "settings/:index",
          component: Settings,
        },
        {
          name: "harbor-dashboard",
          meta: { title: "Harbor Dashboard", requiresAuth: true },
          path: "harbor",
          component: Dashboard,
        },
        {
          name: "Registration",
          meta: { title: "Harbor Registration", requiresAuth: true },
          path: "harbor/add",
          component: Harbor,
        },
        {
          name: "gitlab-dashboard",
          meta: { title: "Gitlab Dashboard", requiresAuth: true },
          path: "gitlab",
          component: GitlabDashboard,
        },
        {
          name: "gitlab-Registration",
          meta: { title: "Gitlab Registration", requiresAuth: true },
          path: "gitlab/add",
          component: Gitlab,
        },
        {
          name: "minio-dashboard",
          meta: { title: "Minio Dashboard", requiresAuth: true },
          path: "minio",
          component: MinioDashboard,
        },
        {
          name: "minio-Registration",
          meta: { title: "Minio Registration", requiresAuth: true },
          path: "minio/add",
          component: Minio,
        },
        {
          name: "argocd-dashboard",
          meta: { title: "ArgoCD Dashboard", requiresAuth: true },
          path: "argocd",
          component: ArgoCDDashboard,
        },
        {
          name: "argocd-Registration",
          meta: { title: "ArgoCD Registration", requiresAuth: true },
          path: "argocd/add",
          component: ArgoCD,
        },
        {
          name: "tritonInference",
          meta: { title: "Triton Inference", requiresAuth: true },
          path: "tritonInference",
          component: TritonInference,
        },
        {
          name: "tritonInferenceDetails",
          meta: { title: "Triton Inference", requiresAuth: true },
          path: "tritonInference/:id",
          component: TritonInferenceDetails,
        },
        {
          name: "createNewModel",
          meta: { title: "New Model", requiresAuth: true },
          path: "triton-inference/create-model",
          component: CreateNewModel,
        },

        {
          name: "airflowDashboard",
          meta: { title: "Airflow", requiresAuth: true },
          path: "airflow",
          component: AirflowDashboard,
        },
      ],
    },
    {
      name: "not-found",
      meta: { title: "Not Found" },
      path: "/:notFound(.*)",
      component: NotFound,
    },
  ],
});

router.beforeEach((to, _, next) => {
  window.document.title = to.meta.title
    ? `Omni-Ops-${to.meta.title}`
    : "Omni-Ops";
  if (to.meta.requiresAuth && !store.getters.authenticated) {
    sessionStorage.clear();
    next("/");
  } else if (
    !to.meta.requiresAuth &&
    store.getters.authenticated &&
    !to.path.startsWith("/harbor")
  ) {
    next("/dashboard/tickets/queue");
  } else if (to.path.length > 1) {
    next();
  } else if (store.getters.authenticated) {
    next("/dashboard/tickets/queue");
  } else {
    sessionStorage.clear();
    next();
    // next("/pre_login");
  }
});

router.afterEach(() => {
  const element = document.getElementById("content-container");
  if (element) {
    element.scrollTop = 0;
  }
});

export default router;
