<template>
  <div>
    <PageHeader
      :title="data.name"
      :isAdvanced="data.isAdvanced"
      :modelId="data.id"
      :isModelDetails="isModelDetails"
      parentLabel="Triton Inference Dashboard"
    />

    <div id="details-container">
      <common-loading v-if="loading" />
      <div v-else-if="error && error.length">
        <b-alert :show="error && error.length ? true : false" variant="danger">
          <div v-html="error || '&nbsp;'"></div>
        </b-alert>
      </div>
      <div v-else-if="data !== undefined">
        <div v-if="data">
          <div id="insights-container">
            <div class="d-flex align-items-center gap-2 pb-4">
              <div :class="['status-tag', data.status]">
                {{ data.status }}
              </div>
              <div class="model-name flex-grow-1 fs-6">
                {{ data.name }}
              </div>
              <div class="flex-grow-2">
                <PrimeButton
                  class="model-button"
                  icon="pi pi-copy"
                  @click="copyModelName(data.name)"
                />
              </div>
            </div>
            <div class="tabs grid-auto-fir-minmax">
              <div
                style="padding: 0rem 0.25rem"
                v-for="insight in data.insights"
                :key="insight.name"
              >
                <div class="tab">
                  <div class="left-border">
                    <div
                      class="label"
                      style="
                        text-transform: capitalize;
                        font-weight: bold;
                        padding-left: 10px;
                      "
                    >
                      {{ insight.name }}
                    </div>
                    <div class="value" style="padding-left: 10px" v-if="data">
                      <span style="">{{ Math.round(insight.value) }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BillingContent />
      </div>

      <NoAccounts v-else />
    </div>
  </div>
</template>

<script>
import BillingContent from "@/pages/Billing/AWS/components/Content.vue";
// import CostService from "../../../services/cost.service";
import NoAccounts from "@/pages/Billing/components/NoAccounts.vue";
import PageHeader from "@/components/ui/PageHeader.vue";

export default {
  name: "Triton-inference-details",
  components: { NoAccounts, PageHeader, BillingContent },
  data() {
    return {
      loading: false,
      error: null,
      isModelDetails: true,
      data: {
        isAdvanced: true,
        id: "1",
        status: "deploying",
        name: "Model_Name",
        insights: [
          {
            name: "GPU Memory Used",
            value: 10,
          },
          {
            name: "GPU Power usage",
            value: 70,
          },
          {
            name: "GPU Power Limit 0",
            value: 100,
          },
          {
            name: "GPU Power Limit 1",
            value: 100,
          },
          {
            name: "GPU Power Limit 2",
            value: 100,
          },
          {
            name: "GPU Power Limit 3",
            value: 100,
          },
          {
            name: "GPU Power Limit 4",
            value: 100,
          },
          {
            name: "GPU Power Limit 5",
            value: 100,
          },
        ],
      },
    };
  },
  mounted() {
    //TODO: Fetch data from API
    // this.loading = true;
    // this.error = null;
    // CostService.Stats()
    //   .then(({ data }) => {
    //     this.data = data;
    //   })
    //   .catch(({ message }) => {
    //     this.error = message ? message : "There was an error in loading stats.";
    //   })
    //   .finally(() => {
    //     this.loading = false;
    //   });
  },
  methods: {
    copyModelName(modelName) {
      let formattedModelName = modelName.replace(/\s+/g, "_");
      let url = "http://triton.omniops.sa/v2/models/" + formattedModelName;
      navigator.clipboard.writeText(url);

      this.$toast.add({
        severity: "success",
        summary: "Success Message",
        detail: "copied to clipboard",
        life: 3000,
      });
    },
  },
};
</script>

<style scoped>
#details-container {
  margin-top: 110px;
  padding: 0px 25px;
}

#details-container .tab {
  background-color: #13d39e;
  border-radius: 4px;
  border: 1px solid #89e9cf;
  color: #112849;
  text-align: center;
  padding: 15px;
  cursor: pointer;
}

#details-container .tab .label {
  font-size: 14px;
}

#details-container .tab .value {
  font-size: 26px;
}

#details-container .tab .value .red-label {
  font-size: 14px;
  color: #ce1a36;
  margin-left: 0.25rem;
}

#details-container .tab .value .red-label img {
  margin-right: 0.15rem;
  margin-top: -1px;
}

#content-container .top-container .filter-tab[data-v-71fd258a] {
  background-color: #89e9cf;
  color: #13d39e;
  border-radius: 6px;
  font-size: 14px;
  padding: 5px 10px;
  text-align: center;
  cursor: pointer;
}

.left-border {
  border-left: #89e9cf 3px solid;
}

#insights-container {
  padding: 0px 40px 0px 25px;
}

#insights-container .tab {
  background-color: #e7fbf5;
  border-radius: 4px;
  border: 1px solid #89e9cf;
  color: #112849;
  text-align: left;
  padding: 15px;
  cursor: pointer;
}

#insights-container .tab .label {
  font-size: 14px;
}

#insights-container .tab .value {
  font-size: 26px;
}

.status-tag {
  font-size: 14px;
  line-height: 18px;
  padding: 5px 10px;
  border-radius: 16px;
  text-transform: capitalize;
}

.deploying {
  background-color: #f0f6ff;
  border: 1px solid #bfdbfe;
  color: #2463eb;
}

.deployed {
  background-color: #e7fbf5;
  border: 1px solid #5ae0bb;
  color: #0fa97e;
}

.conflict {
  background-color: #fff8f0;
  border: 1px solid #fee1bf;
  color: #c27113;
}

.model-name {
  color: #083e47;
  font-size: 16px;
  font-weight: 500;
}

.model-button {
  background-color: #13d39e !important;
  color: #021214 !important;
  border: none !important;
  width: 72px !important;
  height: 48px !important;
}

.grid-auto-fir-minmax {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
}
</style>
